
const AksData = {
  aksha: [
    {
      id: 1,
      name: "Dames worsteltoernooi Duisland 2017/2018",
      img: "FotoMo2.png",
    },
    {
      id: 3,
      name: "Buurtsportcoach",
      img: "FotoMo4.png",
    },
    {
      id: 4,
      name: "Europese Kampionschap Pahlavani",
      img: "FotoMo11.png",
    },
    {
      id: 5,
      name: "Groepstraining",
      img: "FotoMo12.png",
    },
    {
      id: 7,
      name: "Alysh Worstelen - Kirgistan",
      img: "FotoMo3.png",
    },
    {
      id: 2,
      name: "School",
      img: "FotoMo7.png",
    },
    {
      id: 6,
      name: "Groepstraining",
      img: "FotoMo13.png",
    },
    {
      id: 8,
      name: "Individueel",
      img: "img30.jpg",
    },
    {
      id: 11,
      name: "School",
      img: "FotoMo6.png",
    },
    {
      id: 9,
      name: "Worsteling Maat",
      img: "Maat.png",
    },
    {
      id: 13,
      name: "Buvaisar Saitiev",
      img: "FotoMo15.jpeg",
    },
    {
      id: 14,
      name: "Individueel",
      img: "MoDesk6.png",
    },
    {
      id: 10,
      name: "International Wrestling Coach",
      img: "new-foto (8).jpg",
    },
    {
      id: 12,
      name: "Clinics voor kinderen",
      img: "img4.jpeg",
    },
  ],
};


export default AksData
<template>
	<header>
		<nav class="cont">
			<!-- Logo -->
			<section class="logo">
				<router-link to="/" class="a">
					<img
						src="../assets/img2/Logo-Circle.png"
						class="img"
						alt="Logo"
					/>
				</router-link>
				<span class="span">
					<h3 class="tt">Mo Maleki</h3>
					<p class="tt">Fit with Mo!</p>
				</span>
			</section>
			<section class="nav-links">
				<ul v-show="!mobile">
					<router-link to="/" class="a2 tt">Home</router-link>
					<router-link to="overmij" class="a2 tt">
						Over mij
					</router-link>
					<router-link to="galerij" class="a2 tt">
						Galerie
					</router-link>
					<router-link to="tarieven" class="a2 tt">
						Tarieven
					</router-link>
					<router-link to="contact" class="a2 tt">
						Contact
					</router-link>
				</ul>
			</section>
		</nav>

		<section class="" @click="toggleMobilNav" v-if="mobile">
			<div v-if="iconOpen">
				<i class="menu-icon fa fa-bars"></i>
			</div>
			<div v-if="iconDicht">
				<i class="menu-icon fa fa-times"></i>
			</div>
		</section>

		<transition name="mobile-nav">
			<ul class="mobile-nav" v-if="mobilNav" id="box">
				<div class="inner1"></div>
				<div class="inner2"></div>
				<div class="inner3"></div>
				<router-link :to="{ path: '/' }" class="a1" id="link"
					>Home</router-link
				>
				<router-link :to="{ path: '/overmij' }" class="a1" id="link2">
					Over mij
				</router-link>
				<router-link :to="{ path: '/galerij' }" class="a1" id="link3">
					Galerie
				</router-link>
				<router-link :to="{ path: '/tarieven' }" class="a1" id="link4">
					Tarieven
				</router-link>
				<router-link :to="{ path: '/contact' }" class="a1" id="link5">
					Contact
				</router-link>
			</ul>
		</transition>
	</header>
</template>

<script>
	export default {
		name: 'NavMobDesk',

		data() {
			return {
				mobile: null,
				mobilNav: null,
				windowWidth: null,
				iconOpen: null,
				iconDicht: null,
			}
		},

		watch: {
			$route() {
				this.mobilNav = false
				this.iconDicht = false
				this.iconOpen = true
			},
		},

		created() {
			window.addEventListener('resize', this.checkScreen)
			// Call de function
			this.checkScreen()

			// const box = document.getElementById('box')
		},

		methods: {
			// Make a screen check voor innerWidth
			checkScreen() {
				this.windowWidth = innerWidth
				if (this.windowWidth < 768) {
					this.mobile = true
					this.iconOpen = true

					return
				}
				this.mobile = false
				this.mobilNav = false
				return
			},

			// Icon toggel
			toggleMobilNav() {
				this.mobilNav = !this.mobilNav
				if (this.mobilNav) {
					this.iconOpen = false
					this.iconDicht = true
				} else {
					this.iconOpen = true
					this.iconDicht = false
				}
			},
		},
	}
</script>

<style lang="scss" scoped>
	header {
		width: 1200px;
		min-height: auto;
		max-height: 600px;
		margin: 0 auto;
		position: sticky;
		top: 0;
		z-index: 999;
		/* Mobile S */
		@media (min-width: 280px) and (max-width: 320px) and (orientation: portrait) {
			width: 100%;
			max-width: 320px;
			max-height: 200px;
		}
		/* Mobile M */
		@media (min-width: 321px) and (max-width: 411px) and (orientation: portrait) {
			width: 320px;
			max-width: 360px;
			min-height: 200px;
		}
		/* Mobile L */
		@media (min-width: 412px) and (max-width: 480px) and (orientation: portrait) {
			width: 440px;
			min-height: 200px;
		}
		/* Tablet */
		@media (min-width: 480px) and(max-width: 767px) and (max-height: 1025px) and (orientation: portrait) {
			width: 760px;
			margin: 0 20px 0 0;
			position: relative;
			left: -50px;
			top: 0;
		}

		/* TV */
		@media (min-width: 2200px) {
			width: 2400px;
			max-width: 2500px;
			margin: 0 auto;
		}
	}

	.cont {
		min-width: 1200px;
		max-width: auto;
		z-index: 99;
		padding: 0 25px;
		margin: 0 auto;
		display: grid;
		grid-template-columns: 1fr 2fr;

		/* Mobile S */
		@media (min-width: 280px) and (max-width: 320px) and (orientation: portrait) {
			width: 280px;
			max-width: 300px;
		}
		/* Mobile M */
		@media (min-width: 321px) and (max-width: 411px) and (orientation: portrait) {
			width: 330px;
			max-width: 380px;
			margin: 0 auto;
		}
		/* Mobile L */
		@media (min-width: 412px) and (max-width: 480px) and (orientation: portrait) {
			width: 400px;
			max-width: 440px;
			margin: 0 auto;
		}

		/* TV */
		@media (min-width: 2200px) {
			min-width: 2200px;
			max-width: auto;
		}

		.a2 {
			text-decoration: none;
			margin: 0 7px;
			transition: 0.4s color ease;
			font-size: 20px;
		}

		.logo {
			padding-top: 5px;
			display: grid;
			grid-template-columns: 1fr 2fr;
			margin-left: 50px;

			/* Mobile S */
			@media (min-width: 280px) and (max-width: 321px) and (orientation: portrait) {
				padding: 0 10px;
				width: 120px;
				display: flex;
				left: -70px;
				position: relative;
			}

			/* Mobile M  */
			@media (min-width: 321px) and (max-width: 411px) and (orientation: portrait) {
				padding: 0 10px;
				width: 120px;
				display: flex;
				left: -80px;
				position: relative;
			}

			/* Mobile L  */
			@media (min-width: 412px) and (max-width: 480px) and (orientation: portrait) {
				padding: 0 10px;
				width: 150px;
				display: flex;
				left: -60px;
				position: relative;
			}

			img {
				width: 100px;
				height: 100px;
				margin-left: 10px;

				@media (max-width: 480px) {
					width: 70px;
					height: 70px;
					margin-left: -5px;
					margin-top: 5px;
				}
			}

			.span {
				display: flex;
				flex-direction: column;
				margin-left: -55px;
				margin-top: -40px;
				justify-content: center;
				align-items: center;
				color: #ff8008;
				font-size: 18px;
				font-weight: bold;
				width: 300px;

				//Mobile S
				@media (min-width: 280px) and (max-width: 320px) {
					width: 200px;
					margin-left: -25px;
					margin-top: 5px;
				}

				/* Mobile M */
				@media (min-width: 321px) and (max-width: 411px) {
					margin-left: -10px;
					margin-top: -10px;
				}

				// Mobile L
				@media (min-width: 412px) and (max-width: 480px) and (orientation: portrait) {
					width: 250px;
					margin: 0;
					position: relative;
					justify-content: space-around;
				}

				h3 {
					font-family: 'Roboto', Garamond, 'Times New Roman', serif;
					padding-left: 10px;
					padding-top: 25px;
					margin-bottom: 0;
					font-size: 28px;
					font-weight: 700;
					text-shadow: 4px 3px 3px #0e103d;
					letter-spacing: 0;
					color: #fff;

					@media (max-width: 480px) {
						width: 200px;
						padding-top: 0;
						font-size: 25px;
					}
				}
				p {
					font-family: Garamond, 'Times New Roman', Times, serif;
					padding-left: 1px;
					position: relative;
					color: #0e103d;
					font-weight: 700;
					text-shadow: 4px 3px 5px #ff8008;
					letter-spacing: 0;
					font-size: 20px;
					margin-bottom: 0;

					//Mobile S
					@media (min-width: 280px) and (max-width: 320px) {
						margin-left: 10px;
					}

					/* Mobile M */
					@media (min-width: 321px) and (max-width: 411px) {
						margin-left: 15px;
					}

					// Mobile L
					@media (min-width: 412px) and (max-width: 480px) and (orientation: portrait) {
						width: 200px;
						margin-top: -20px;
						margin-left: 10px;
						font-size: 18px;
					}
				}
			}
		}
	}

	.nav-links {
		position: relative;
		display: flex;
		flex: 1;
		align-items: center;
		justify-content: flex-end;
		margin-right: 40px;

		.a2.router-link-exact-active {
			color: #fff !important;
			/* border: 1px solid #FF8008; */
			border: 1px solid #ff8008; /* fallback for old browsers */
			border: 1px solid -webkit-linear-gradient(to left, #ffc837, #ff8008); /* Chrome 10-25, Safari 5.1-6 */
			border: 1px solid linear-gradient(to left, #ffc837, #ff8008); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
			border-radius: 10px 0;
			padding: 7px 8px;
			background-color: #0e103dbb;
		}
	}

	/* Mobile Style */
	.menu-icon {
		cursor: pointer;
		position: absolute;
		top: 20px;
		right: 25px;
		width: auto;
		font-size: 40px;
		z-index: 111;
		color: #0e103d;
		background-color: #ff8008;
		padding: 7px 10px;
		border-radius: 5px;
		transition: all 0 1s ease;

		// Mobile L
		@media (min-width: 412px) and (max-width: 480px) and (orientation: portrait) {
			margin-right: 40px;
		}
	}

	.mobile-nav {
		border-radius: 50%;
		display: flex;
		flex-direction: column;
		// background-color: #0e103d;
		position: absolute;
		justify-content: center;
		z-index: 2;
		// padding: 40px;

		@media (min-width: 240px) and (max-width: 320px) and (orientation: portrait) {
			width: 220px;
			height: 220px;
			top: 120px;
			margin-left: -50px;
		}
		@media (max-width: 480px) {
			width: 300px;
			height: 300px;
			top: 160px;
			// margin: 0 auto;
			left: 60px;
		}

		.a1 {
			text-decoration: none;
			margin: 5px;
			transition: 0.4s color ease;
			font-weight: bold;
			letter-spacing: 1px;
			color: #0e103d;
			text-shadow: 0 0 5px #ff8008;
			font-size: 20px;
			padding: 2px;
			z-index: 99;

			&.router-link-exact-active {
				color: #e90707 !important;
				border: 1px solid #ff8008;
				padding: 5px 0 !important;
				margin: 1px 90px !important;
			}
		}

		// Circle van mat
		.inner1 {
			background-color: rgba(223, 23, 23, 0.932);
			width: 280px;
			height: 280px;
			border-radius: 50%;
			position: absolute;
			margin-left: 10px;
			z-index: 30;
		}
		.inner2 {
			background-color: rgba(247, 243, 25, 0.925);
			width: 230px;
			height: 230px;
			border-radius: 50%;
			position: absolute;
			margin-left: 35px;
			z-index: 30;
		}
		.inner3 {
			background-color: none;
			width: 50px;
			height: 50px;
			border-radius: 50%;
			position: absolute;
			margin-left: 125px;
			border: 3px solid #fff;
			z-index: 30;
		}
	}

	// Animations
	.mobile-nav-enter-active,
	.mobile-nav-leave-active {
		transition: all 0.8s ease-in-out;
	}

	.a1.router-link-exact-active {
		color: #fff !important;
		/* border: 1px solid #FF8008; */
		border: 1px solid #ff8008; /* fallback for old browsers */
		border: 1px solid -webkit-linear-gradient(to left, #ffc837, #ff8008); /* Chrome 10-25, Safari 5.1-6 */
		border: 1px solid linear-gradient(to left, #ffc837, #ff8008); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
		border-radius: 10px 0;
		padding: 7px;
		margin: 0 90px;

		@media (max-width: 320px) {
			margin: 0 70px;
			padding: 7px;
		}
		@media (max-width: 480px) {
			margin: 0 70px;
			padding: 7px;
		}
	}

	.mobile-nav-enter {
		transform: scale(0.7);
		transition: all 1s ease-in-out;
	}
	.mobile-nav-leave {
		transform: scale(1);
		transition: all 0.4s ease-in-out;
	}

	.mobile-nav-enter-to {
		transform: scale(1.2);
		transition: scale 0.3s 0s 1s ease-in-out;
	}
	.mobile-nav-leave-to {
		transform: scale(0.8);
		transition: scale 0.8s 0s 1s ease-in-out;
	}
</style>

import firebase from 'firebase/app'
import 'firebase/firestore'

// Your web app's Firebase configuration
var firebaseConfig = {
	apiKey: 'AIzaSyBvFNmLQhXsP_ja4HTN64dvJOmNwwtN1w8',
	authDomain: 'momaleki-nl.firebaseapp.com',
	projectId: 'momaleki-nl',
	storageBucket: 'momaleki-nl.appspot.com',
	messagingSenderId: '231492982218',
	appId: '1:231492982218:web:88a54f2d793cbbb57beb33',
}
// Initialize Firebase
export const db = firebase.initializeApp(firebaseConfig).firestore()

<template>
	<article class="col-12-xs
					col-12-sm
					col-12-md
					col-12-ml
					col-12-lg
					col-12-xl
					col-12-xx p-1">
		<h3 class="font-xl mb-3">Clinics voor kinderen</h3>
		<article class="row justify-center">
			<section class="col-12-xs
					col-12-sm
					col-12-md
					col-6-ml
					col-6-lg
					col-6-xl
					col-6-xx">
				<p class="lead p-1 mt-3">
					In de afgelopen jaren heb ik veel ervaring opgebouwd in het
					werken met kinderen, onder andere als buurtsportcoach,
					worsteltrainer en met het geven van gymlessen op
					verschillende scholen. Met veel plezier en enthousiasme geef
					ik allerlei sport-clinics, zoals bootcamp en worstelen. Van
					speelsgewijs stoeien met de jongsten, tot echte
					worsteltechnieken leren voor de oudere jeugd, er is van
					alles mogelijk! Ik geef gastlessen op basisscholen en BSO’s,
					maar ook daarbuiten kunnen we clinics organiseren. Worstelen
					kan ook een hele mooie manier zijn om kwetsbare kinderen
					meer kracht en zelfvertrouwen te geven. Geïnteresseerd? Neem
					contact op!
				</p>
			</section>
			<section class="col-12-xs
					col-12-sm
					col-12-md
					col-6-ml
					col-6-lg
					col-6-xl
					col-6-xx p-2">
				<img
					src="../assets/img2/img4.jpeg"
					alt="img4"
					class="img aks"
				/>
			</section>
		</article>
	</article>
</template>

<script>
	export default {
		name: 'Section4'
	}
</script>


<template>
	<article
		class="col-12-xs
					col-12-sm
					col-12-md
					col-12-ml
					col-12-lg
					col-12-xl
					col-12-xx "
	>
		<h3 class="font-xl">Worstelen</h3>
		<div class="row justify-center p-2">
			<section
				class="col-12-xs
					col-12-sm
					col-12-md
					col-6-ml
					col-6-lg
					col-6-xl
					col-6-xx"
			>
				<p class="lead p-1">
					Worstelen is mijn grote passie. Deze eeuwenoude sport heeft
					veel facetten. Natuurlijk kracht, maar vooral ook techniek
					en tactisch nadenken. In mijn jeugd heb ik op sub-olympisch
					niveau geworsteld. Nu vind ik het leuk om mijn kennis door
					te geven en mensen te laten kennismaken met deze prachtige
					sport. De technieken kunnen goed van pas komen binnen
					allerlei vechtsporten, zoals ook MMA. Maar ook is het een
					sport die je psychisch sterk maakt en steviger in je
					schoenen doet staan. Benieuwd? Neem contact op!
				</p>

				<router-link to="/overmij/#ov" class="btn btn-primary text-secondary">
					Meer
				</router-link>
			</section>
			<section
				class="col-12-xs
					col-12-sm
					col-12-md
					col-6-ml
					col-6-lg
					col-6-xl
					col-6-xx p-2"
			>
				<img
					src="../assets/img2/FotoMo13.png"
					alt="img39"
					class="aks img"
				/>
			</section>
		</div>
	</article>
</template>

<script>
	export default {
		name: 'Section3',
	}
</script>





<template>
	<article
		class="col-12-xs
					col-12-sm
					col-12-md
					col-12-ml
					col-12-lg
					col-12-xl
					col-12-xx p-1"
	>
		<h2 class="font-xl">
			Via deze manieren kunt u met ons contact opnemen
		</h2>

		<br />

		<div
			class="col-12-xs
					col-12-sm
					col-12-md
					col-12-ml
					col-12-lg
					col-12-xl
					col-12-xx"
		>
			<Gegevens />
		</div>

		<br />
		<br />
		<hr />

		<FormJadid />
	</article>
</template>

<script>
	// import Form from '@/components/Form.vue'
	import FormJadid from '@/components/FormJadid.vue'
	import Gegevens from '@/components/Gegevns.vue'
	//import Handel from '@/components/Handel.vue'

	export default {
		name: 'Contact',
		components: {
			FormJadid,
			Gegevens,
		},
	}
</script>


<template>
	<main
		class="col-12-xs
					col-12-sm
					col-12-md
					col-12-ml
					col-12-lg
					col-12-xl
					col-12-xx p-1"
	>
		<!-- Section Geschiedenis -->
		<h4 class="tt a">Geschiedenis</h4>
		<article
			class="row p-2 justify-center col-12-xs
					col-12-sm
					col-12-md
					col-12-ml
					col-12-lg
					col-12-xl
					col-12-xx"
		>
			<section
				class="col-12-xs
					col-12-sm
					col-12-md
					col-6-ml
					col-6-lg
					col-6-xl
					col-6-xx "
			>
				<p class="lead p-1">
					Sport is van jongs af aan mijn grote passie. In mijn jeugd
					heb ik op sub-olympisch niveau geworsteld. Toen ik naar
					Nederland kwam heb ik mijn liefde voor deze sport meegenomen
					en tot op de dag van vandaag ben ik actief in de
					worstelsport. Vanuit deze basis heb ik me in allerlei takken
					van sport ontwikkeld. Ik heb veel ervaring opgedaan in het
					begeleiden van sport en beweging, in groepen en individueel
					en van jong tot oud. In de afgelopen jaren was en ben ik
					onder andere: buurtsportcoach, gymleraar, keepfit-trainer,
					bootcamp-instructeur, worstelleraar en natuurlijk personal
					trainer.
				</p>
			</section>
			<section
				class="col-12-xs
					col-12-sm
					col-12-md
					col-6-ml
					col-6-lg
					col-6-xl
					col-6-xx"
			>
				<img
					src="../assets/img2/MoFoto2.jpg"
					alt="Mo-Foto"
					class=" aks "
				/>
			</section>
		</article>

		<hr />

		<!-- Section Mijn Passie -->
		<h4 class="tt b my-2">Mijn passie</h4>
		<article
			class="p-2 justify-center col-12-xs row
					col-12-sm
					col-12-md
					col-12-ml
					col-12-lg
					col-12-xl
					col-12-xx"
		>
			<section
				class="col-12-xs
					col-12-sm
					col-12-md
					col-6-ml
					col-6-lg
					col-6-xl
					col-6-xx p-2"
			>
				<p class="lead p-1">
					Ik hou ervan om met mensen te werken. Een persoonlijke
					aanpak vind ik heel belangrijk. Wat wil jij bereiken? Graag
					maak ik met jou een plan op maat, gericht op jouw wensen en
					toewerkend naar jouw doelen. Altijd zet ik mij voor de volle
					100% in bij alles wat ik doe. Ik kan streng zijn, maar
					altijd met een lach. Wil jij de uitdaging met mij aangaan?
				</p>
			</section>
			<section
				class="col-12-xs
					col-12-sm
					col-12-md
					col-6-ml
					col-6-lg
					col-6-xl
					col-6-xx"
			>
				<img
					src="../assets/img2/MoFoto3.jpg"
					alt="Mo-Foto-2"
					class="aks"
				/>
			</section>
		</article>
	</main>
</template>

<script>
	export default {
		name: 'Overmijn',
	}
</script>

<template>
	<main class="jumbotron">
		<article class="cont">
			<section class="row">
				<div class="col"></div>
			</section>
		</article>
	</main>
</template>

<script>
	export default {
		name: 'Mo',
	}
</script>

<style scoped lang="scss">
	.jumbotron {
		background-image: url('../assets/img2/MoDesk5.png');
		min-width: 100%;
		max-width: auto;
		min-height: 500px;
		max-height: auto;
		object-fit: cover;
		background-size: cover;
		background-origin: center;
		background-attachment: center;
		background-position: center;
		top: -110px;
		margin: 0 auto;
		z-index: 1;

		/* Mobile S */
		@media (min-width: 240px) and (max-width: 320px) and (orientation: portrait) {
			background-image: url('../assets/img2/MoMobil4.png');
			width: auto;
			max-width: 320px;
			max-height: 120px;
			object-fit: cover;
			background-size: contain;
			background-origin: center;
			background-attachment: center;
			background-position: center;
			background-repeat: no-repeat;
			margin-top: -78px;
		}

		/* Mobile M */
		@media (min-width: 321px) and (max-width: 412px) and (orientation: portrait) {
			background-image: url('../assets/img2/MoMobil4.png');
			width: auto;
      min-width: 321px;
			max-width: 412px;
			max-height: 150px;
			object-fit: cover;
			background-size: contain;
			background-origin: center;
			background-attachment: center;
			background-position: center;
			background-repeat: no-repeat;
			margin-top: -185px;
		}
		/* Mobile L */
		@media (min-width: 412px) and (max-width: 480px) and (orientation: portrait) {
			min-width: 100%;
			max-width: 480px;
			min-height: 180px;
			max-height: 200px;
			background-image: url('../assets/img2/MoMobil4.png');
			top: -125px;
			margin: auto;
		}
	}

	/* .cont {
		z-index: 0;

		@media screen and (min-width: 300px) and (max-width: 480px) {
	
		}
	} */

	/*
  * 
  *         ***************                 Mobile Old             	***************
  * 
  * 
   */

	/* Mobile Portrait Old  */
	/*  @media only screen and (max-width: 320px) and (orientation: portrait) {
    .jumbotron {
      background-image: url("../assets/img2/MoMobil4.png");
      background-size: contain no-repeat;
      background-origin: center;
      background-attachment: center;
      background-position: center;
      min-width: 300px;
      max-width: 400px;
      height: 150px;
      margin-top: -18px;
      display: flex;
      padding-right: 50px;
    }
  } */

	/* Mobile Landscape Old */
	/*   @media only screen and (min-width: 560px) and (min-height: 310px) and (max-width: 767px) and (orientation: landscape) {
    .jumbotron {
      background-image: url("../assets/img2/MoMobil4.png");
      background-size: cover;
      background-origin: center;
      background-attachment: center;
      background-position: center;
      width: auto;
      height: 220px;
      margin-top: -90px;
      display: flex;
    }
  } */

	/*
  * 
  *       ***************                 Mobile New             	***************
  * 
  * 
   */

	/* Mobile Portrait New */
	/*  @media only screen and (min-width: 400px) and (orientation: portrait){
    .jumbotron {
      background-image: url("../assets/img2/MoMobil4.png");
      background-size: cover;
      background-origin: center;
      background-attachment: center;
      background-position: center;
      min-width: 400px;
      max-width: 480px;
      height: 200px;
      margin-top: -18px;
      display: flex;
      padding-right: 50px;
    }
  }  */

	/* Mobile Landscape New */
	/* @media only screen and (min-width: 800px) and (min-height: 400px) and (orientation: landscape){
    .jumbotron {
      background-image: url("../assets/img2/MoMobil4.png");
      background-size: cover;
      background-origin: center;
      background-attachment: center;
      background-position: center;
      min-width: auto;
      max-width: auto;
      height: 350px;
      margin-top: -90px;
      display: flex;
      padding-right: 50px;
    }
  } */
</style>

<template>
	<article class="jumbotron">
		<section class="cont">
			<div class="row">
				<div class="col">
					<p class="lead">Personal Training</p>

					<button
						class="btn-mij"
						id="btn"
						type="button"
						@click="scrol()"
					>
						<a class="a" href="#ga">Kijk verder </a>
						<a href="#ga" class="">
							<i
								class="fas fa-angle-double-down fa-2x icon text-rosh"
							></i>
						</a>
					</button>
					<br />
				</div>
			</div>
		</section>
	</article>
</template>

<script>
	export default {
		name: 'Mo3',

		data: () => {
			return {
				move: false,
			}
		},

		methods: {
			scrol() {
				document.querySelector('#btn').scrollIntoView({
					behavior: 'smooth',
				})
			},
		},
	}
</script>

<style scoped lang="scss">
	.jumbotron {
		background-image: url('../assets/img2/MoDesk7.png');
		width: 100%;
		height: 75vh !important;
		top: -58px;

		.cont {
			width: 1180px;
			margin: 0 auto;
		}

		.row {
			display: block;
			position: absolute;
			top: 150px;
			left: 0;

			.col {
				margin-top: 75px;
			}

			.lead {
				font-size: 65px !important;
				color: var(--text-primary);
				font-weight: 700;
				text-shadow: 3px 2px 5px #ff8008;
				font-family: 'Roboto', Garamond, sans-serif;
				letter-spacing: 1px;
				margin-top: 45px;
			}

			.btn-mij {
				margin-top: 180px;
				font-size: 18px;
				padding: 10px 13px;
				font-weight: bold;
				border-radius: 4px;
				font-family: 'Roboto', Garamond, sans-serif;

				.icon {
					color: var(--secondary);
					margin-top: 10px;
					z-index: 111;
					display: block;
					position: absolute;
					margin-left: 30px;
				}
			}
		}
	}

	/*
  *
  *         ***************                 Mobile Old             	***************
  *
  *
   */

	/* Mobile Portrait Old  */
	@media (min-width: 280px) and (max-width: 321px) and (orientation: portrait) {
		.jumbotron {
			width: 100%;
			max-width: 320px;
			height: 100%;
			min-height: auto;
			max-height: 170px;
			background-image: url('../assets/img2/MoMobil5.png');
			background-size: cover;
			background-origin: left;
			background-attachment: left;
			background-position: left;
			margin-top: 55px;

			.cont {
				width: 300px;
				left: 0;
				top: -8px;

				.row {
					top: 0;
					height: 200px;
					width: 100%;
					padding-bottom: 0;

					.lead {
						font-size: 25px !important;
						color: var(--text-primary);
						font-weight: 700;
						text-shadow: 3px 2px 5px #ff8008;
						font-family: 'Roboto', Garamond, sans-serif;
						letter-spacing: 1px;
						margin-top: -70px;
						margin-right: -70px;
					}
					.btn-mij {
						margin-top: 72px;
						font-size: 16px;
						padding: 4px 8px;
						font-weight: bold;
						border-radius: 4px;
						font-family: 'Roboto', Garamond, sans-serif;
						position: absolute;
						margin-left: -40px;

						.icon {
							color: var(--secondary);
							left: 14%;
							margin-top: 4px;
							z-index: 111;
							display: flex;
							position: absolute;
						}
					}
				}
			}
		}
	}

	/* Mobile Landscape Old */
	/*   @media (min-width: 560px) and (max-width: 767px) and (orientation: landscape) {
    .jumbotron {
      background-image: url("../assets/img2/MoMobil5.png");
      background-size: cover;
      background-origin: left;
      background-attachment: left;
      background-position: left;
      width: auto;
      height: 250px;
      margin-top: -90px;
      display: flex;

      .container {
        width: 300px;
        left: 0;
       

        .row {
          top: 130px;
          height: auto;
          width: 100%;
          padding-bottom: 0;
          left: -30px;


          .lead {
            font-size: 28px;
            color: var(--text-primary);
            font-weight: 700;
            text-shadow: 3px 2px 5px #ff8008;
            font-family: "Roboto", Garamond, sans-serif;
            letter-spacing: 1px;
            margin-top: 4px;
            margin-right: -85px;
          }
          .btn-mij {
            margin-top: 4px;
            font-size: 14px;
            padding: 6px 8px;
            font-weight: bold;
            border-radius: 4px;
            font-family: "Roboto", Garamond, sans-serif;
            position: absolute;
            margin-left: -4px;

            .icon {
              color: var(--secondary);
              left: 14%;
              margin-top: 4px;
              z-index: 111;
              display: flex;
              position: absolute;
            }
          }
        }
      }
    }
  } */

	/*
  * 
  *       ***************                 Mobile New             	***************
  * 
  * 
   */

	/* Mobile M */
	@media (min-width: 321px) and (max-width: 412px) and (orientation: portrait) {
		.jumbotron {
			width: auto;
			max-width: 412px;
			height: auto;
			max-height: 170px;
			margin-top: -65px;
			display: flex;

			.cont {
				width: 300px;

				.row {
					top: 20px;
					height: 200px;
					width: 100%;
					padding-bottom: 0;

					.lead {
						position: relative;
						display: flex;
						font-size: 26px !important;
						margin-left: 95px;
						margin-top: -80px;
					}
					.btn-mij {
						margin-top: 50px;
						padding: 6px 8px;
						margin-left: 30px;

						.icon {
							color: var(--secondary);
							position: absolute;
						}
					}
				}
			}
		}
	}

	/* Mobile L */
	@media (min-width: 412px) and (max-width: 480px) and (orientation: portrait) {
		.jumbotron {
			width: auto;
			max-width: 480px;
			height: auto;
			max-height: 170px;
			margin-top: -65px;
			display: flex;

			.cont {
				width: 300px;

				.row {
					top: 20px;
					height: 200px;
					width: 100%;
					padding-bottom: 0;

					.lead {
						position: relative;
						display: flex;
						font-size: 28px !important;
						margin-left: 105px;
						margin-top: -80px;
					}
					.btn-mij {
						margin-top: 50px;
						padding: 6px 8px;
						margin-left: 30px;

						.icon {
							color: var(--secondary);
							position: absolute;
						}
					}
				}
			}
		}
	}

	/* Mobile Landscape New */
	/*   @media only screen and (min-width: 800px) and (min-height: 400px) and (orientation: landscape) {
     .jumbotron {
      width: auto;
      height: 350px;
      margin-top: -90px;
      display: flex;
      margin-bottom: -20px;

      .container {
        width: 300px;
       

        .row {
          top: 80px;
          left: -30px;


          .lead {
            font-size: 45px;
            margin-top: 4px;
            margin-right: -85px;
          }
          .btn-mij {
            margin-top: 40px;
            position: absolute;
            margin-left: -4px;

            .icon {
              margin-top: 13px;
            }
          }
        }
      }
    }
  } */
</style>

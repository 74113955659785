<template>
	<main class="mt-1">
		<h3 class="font-xl my-1">Personal Training</h3>

		<article
			class="row col-12-xs
					col-12-sm
					col-12-md
					col-12-ml
					col-12-lg
					col-12-xl
					col-12-xx p-1"
		>
			<section
				class="col-12-xs
					col-12-sm
					col-12-md
					col-6-ml
					col-6-lg
					col-6-xl
					col-6-xx"
			>
				<p class="lead pt-2 mb-2">
					Kan ik jou helpen om jouw doelen te bereiken? Meer bewegen,
					fitter worden, afvallen, conditie opbouwen, kracht trainen?
					Samen met jou maak ik een persoonlijk plan en werken we toe
					naar jouw doelen. Het maakt niet uit wat je huidige niveau
					is en hoe intensief je wilt trainen. Van beginner tot
					professional, iedereen is welkom! Buiten sporten of in de
					sportschool, alles is mogelijk! Zin om te starten? Neem
					contact op en we gaan aan de slag!
				</p>

				<router-link
					to="/overmij/#ov"
					class="btn btn-primary text-secondary "
				>
					Meer
				</router-link>
			</section>
			<section
				class="col-12-xs
					col-12-sm
					col-12-md
					col-6-ml
					col-6-lg
					col-6-xl
					col-6-xx p-2"
			>
				<img
					src="../assets/img2/FotoMo8.png"
					alt="img39"
					class="aks img"
				/>
			</section>
		</article>
	</main>
</template>

<script>
	export default {
		name: 'Section1',
	}
</script>


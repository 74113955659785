<template>
	<article>
		<article
			class="col-12-xs
					col-12-sm
					col-12-md
					col-12-ml
					col-12-lg
					col-12-xl
					col-12-xx row justify-center"
		>
			<section
				class="col-12-xs
					col-12-sm
					col-12-md
					col-6-ml
					col-6-lg
					col-6-xl
					col-6-xx p-1"
			>
				<p class="lead p-1">
					<i class="fas fa-mobile  text-pri " id="icon"></i>
					<a href="tel:0031626915932" class="text-pri"
						>06 26 91 59 32</a
					>
				</p>
				<p class="lead p-1">
					<i class="fas fa-envelope   text-pri" id="icon"></i>
					<a class="text-pri" href="mailto:info@momaleki.nl">
						info@momaleki.nl</a
					>
				</p>
				<p class="lead p-1">
					<i class="fab fa-whatsapp  text-pri" id="icon"></i>
					<a
						class=" text-pri"
						href="tel:0031626915932"
						target="_blank"
						>06 26 91 59 32</a
					>
				</p>
				<p class="lead p-1">
					<i class="fab fa-facebook  text-pri" id="icon"></i>
					<a
						class="text-pri"
						href="https://www.facebook.com/momaleki20"
						target="_blank"
					>
						MoMaleki</a
					>
				</p>
			</section>

			<section
				class="col-12-xs
					col-12-sm
					col-12-md
					col-6-ml
					col-6-lg
					col-6-xl
					col-6-xx p-2"
			>
				<iframe
					class="if"
					src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2507899.689088236!2d3.050290033115734!3d52.13212423314829!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xac5eb0b39a4980c9!2sMoMaleki!5e0!3m2!1snl!2snl!4v1598899305463!5m2!1snl!2snl"
					frameborder="0"
					style="border:0;"
					allowfullscreen="true"
					aria-hidden="false"
					tabindex="0"
				></iframe>
			</section>
		</article>
	</article>
</template>

<script>
	export default {
		name: 'Gegevens',
	}
</script>

<style scoped lang="scss">


	.if {
		width: 100%;
		height: 100%;
	}

</style>

<template>
	<article>
		<NavMobDesk />

		<router-view />

		<Footer />
	</article>
</template>

<script>
	import NavMobDesk from '@/components/NavMobDesk'
	import Footer from '@/components/Footer'

	export default {
		name: 'App',
		components: {
			NavMobDesk,
			Footer
		}
	}
</script>

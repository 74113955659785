<template>
	<article
		class="col-12-xs
					col-12-sm
					col-12-md
					col-12-ml
					col-12-lg
					col-12-xl
					col-12-xx "
	>
		<p class="lead">Prijzen zijn per uur!</p>
		<section class="row justify-center gap-1">
			<div
				class=" col-12-xs
					col-12-sm
					col-6-md
					col-4-ml
					col-4-lg
					col-5-xl
					col-4-xx "
			>
				<header class="card-header">Personal Training</header>
				<div class="card-content bg-primary text-secondary  p-1 br ">
					<li class="">1 les <span class="sp">€45</span></li>
					<li class="">10 lessen <span class="sp">€400</span></li>
					<li class="">Proefles <span class="sp">€20</span></li>
				</div>
				<footer class="card-footer">incl. BTW</footer>
			</div>
			<div
				class="col-12-xs
					col-12-sm
					col-6-md
					col-4-ml
					col-4-lg
					col-5-xl
					col-4-xx "
			>
				<header class="card-header">Worstelen</header>
				<div class="card-content bg-primary text-secondary  p-1 br">
					<li class="">
						Worsteltraining individueel
						<span class="sp">€50</span>
					</li>
					<li class="">
						Worstelen conditie <span class="sp">€30</span>
					</li>
					<li class="">
						Groepstrainig worstelen: <br />
						In overleg
					</li>
				</div>
				<footer class="card-footer do">incl. BTW</footer>
			</div>
			<div
				class=" col-12-xs
					col-12-sm
					col-6-md
					col-4-ml
					col-4-lg
					col-5-xl
					col-4-xx "
			>
				<header class="card-header">Kinderclinic</header>
				<div class="card-content bg-primary text-secondary  p-1 br ">
					<li>
						Worstelclinic kinderen basisschool of BSO
						<span class="sp">€35</span>
					</li>
				</div>
				<footer class="card-footer se">excl. BTW</footer>
			</div>
		</section>
	</article>
</template>

<script>
	export default {
		name: 'Paket',
	}
</script>

<style scoped lang="scss">
	li {
		margin: 10px 0;
	}
</style>

<template>
	<article class="col-12-xs
					col-12-sm
					col-12-md
					col-12-ml
					col-12-lg
					col-12-xl
					col-12-xx p-2">
		<h3 class="font-xl">Bootcamp</h3>
		<div class="row justify-center p-1">
			<section
				class="col-12-xs
					col-12-sm
					col-12-md
					col-6-ml
					col-6-lg
					col-6-xl
					col-6-xx"
			>
				<img
					src="../assets/img2/Bootcamp.jpg"
					alt="img36"
					class="aks mt-2 "
				/>
			</section>

			<section
				class="col-12-xs
					col-12-sm
					col-12-md
					col-6-ml
					col-6-lg
					col-6-xl
					col-6-xx"
			>
				<p class="lead p-2">
					Bootcamp is lekker intensief bewegen, een combinatie van
					kracht en conditie. De perfecte work-out om gezond en fit te
					worden en te blijven! Ik ben AALO-gecertificeerd bootcamp
					trainer en heb ervaring met het geven van gevarieerde
					bootcamp-lessen voor verschillende niveaus.
				</p>

				<router-link
					to="/galerij"
					class="btn btn-primary text-secondary"
				>
					Meer
				</router-link>
			</section>
		</div>
	</article>
</template>

<script>
	export default {
		name: 'Section2',
	}
</script>


<template>
	<article class="aa">
		<section class="">
			<Mo3 />
		</section>

		<section class="">
			<Aks />
		</section>

		<br />
		<hr />

		<Felom />
	</article>
</template>

<script>
	import Mo3 from '@/components/Mo3.vue'
	import Aks from '@/components/Aks'
	import Felom from '@/components/Felom'

	export default {
		name: 'Galerij',
		components: {
			Mo3,
			Aks,
			Felom
		}
	}
</script>

<style scoped lang="scss"></style>

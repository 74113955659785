<template>
	<article class="col-12-xs
					col-12-sm
					col-12-md
					col-12-ml
					col-12-lg
					col-12-xl
					col-12-xx mt-2 p-1">
		<!-- <h2>Suggestie of vragen?</h2> -->
		<h2>U kunt ons altijd een bericht sturen</h2>
		<p class="">
			Graag ontvangen we je ideeën, feedback, suggesties en opmerkingen
			over hoe we je ervaring kunnen verbeteren!
		</p>
		<p class="vereist">&ast; Vereist</p>

		<div class="alert" v-if="vull">{{ msg }}</div>
		<form >
			<label for="naam">
				Naam
				<span>&ast;</span>
			</label>
			<input
				type="text"
				class="form-control"
				id="naam"
				v-model="naam"
				placeholder="Uw naam"
			/>
			<label for="achternaam">
				Achternaam
				<span>&ast;</span>
			</label>
			<input
				type="text"
				class="form-control"
				id="achternaam"
				v-model="achternaam"
				placeholder="Uw achternaam"
			/>
			<label for="email">
				Email
				<span>&ast;</span>
			</label>
			<input
				type="email"
				class="form-control"
				id="email"
				v-model="email"
				placeholder="Uw email"
			/>
			<label for="txt">Bericht</label>
			<textarea
				placeholder="Inhoud van het bericht"
				v-model="txt"
			></textarea>
			<button type="submit" class=" btn-primary text-secondary" @click.prevent="addForm()">
				Verzenden
				<i class="fa fa-save"></i>
			</button>
		</form>
	</article>
</template>

<script>
	import formService from '@/services/FormServices'
	export default {
		name: 'FormJadid',

		data() {
			return {
				naam: '',
				achternaam: '',
				email: '',
				txt: '',
				vull: false,
				msg: `Bedankt, De bericht is verzonden.`,
			}
		},
		methods: {
			addForm() {
				const newForm = {
					naam: this.naam,
					achternaam: this.achternaam,
					email: this.email,
					txt: this.txt,
				}

				formService.addForm(newForm)
				this.vull = true
				console.log('OK Shod')
				;(this.naam = ''),
					(this.achternaam = ''),
					(this.email = ''),
					(this.txt = '')

				setTimeout(() => {
					this.$router.push('/')
				}, 4000)
			},
		},
	}
</script>

<style scoped>
	.container {
		width: 850px;
		margin: 0 auto;
		text-align: center;
	}

	form {
		border: 0.1px solid #0e103d6e;
		padding: 20px;
		border-radius: 7px;
	}

	h2 {
		margin-bottom: 10px;
	}

	label {
		text-align: left;
		font-weight: bold;
		position: relative;
		display: flex;
		margin-left: 5px;
		color: #0e103d;
		padding-bottom: 0;
	}

	input {
		display: block;
		padding: 10px;
		width: 100%;
		outline: none;
		border-radius: 7px;
		border: none;
		margin-bottom: 15px;
	}

	span {
		padding: 3px 0 0 2px;
		color: #a35307;
	}

	textarea {
		width: 100%;
		border-radius: 7px;
		border: none;
		padding: 10px;
		height: 200px;
	}

	.btn {
		padding: 10px 17px;
		background: #0e103d;
		color: #ff8008;
		outline: none;
		border: none;
		border-radius: 7px;
		font-size: 22px;
		margin-top: 5px;
	}

	.mb-4 {
		margin-bottom: 35px;
	}

	.vereist {
		text-align: left;
		color: #a85201;
		font-size: 20px;
		margin-left: 10px;
	}

	.alert {
		background-color: #ffd5d5;
		color: #147742;
		padding: 10px;
		margin-bottom: 5px;
		transition: all 1s ease-in;
		border-radius: 7px;
	}

	/* Mobile Portrait Old  */
	@media (min-width: 280px) and (max-width: 321px) and (orientation: portrait) {
		.container {
			margin-top: 40px;
		}

		h2 {
			margin-top: 50px;
		}
	}

	/* Mobile M */
	@media (min-width: 321px) and (max-width: 412px) and (orientation: portrait) {
		h2 {
			margin-top: 50px;
		}
	}

	/* Mobile L */
	@media (min-width: 412px) and (max-width: 480px) and (orientation: portrait) {
		h2 {
			margin-top: 20px;
		}
	}
</style>

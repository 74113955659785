<template>
	<article>
		<p class=" txt">Onze tarieven</p>
		<Paket class="paket" />
		<br />
	</article>
</template>

<script>
	import Paket from '@/components/Paket.vue'

	export default {
		name: 'Tarieven',
		components: { Paket }
	}
</script>

<style scoped lang="scss">
	.txt {
		font-size: 45px;
	}

		/* Mobile S */
		@media (min-width: 280px) and (max-width: 320px) and (orientation: portrait)  {
		.txt {
			padding-top: 20px;
      font-size: 35px;
      padding-bottom: 0;
      margin-bottom: -20px;
		}
	}

	/*	Mobile M	*/
	@media (min-width: 321px) and (max-width: 411px) and (orientation: portrait) {
		.txt {
			margin-top: -90px;
		}
	}

	/* Mobile L */
	@media (min-width: 412px) and (max-width: 480px) and (orientation: portrait) {
		.txt {
			margin-top: -70px;
		}
	}

	/* LAndscape */
	/*   @media (min-width: 431px) and (max-width: 979px) {
        
    } */

	/* Landscape Tablet */
	/*  @media only screen 
      and (min-device-width: 1112px) 
      and (max-device-width: 1112px) 
      and (orientation: Landscape) 
      and (-webkit-min-device-pixel-ratio: 2){
      } */
</style>

import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import OverMij from "../views/OverMij.vue";
import Galerij from "../views/Gallerij.vue";
import Contact from "../views/Contact.vue";
import Tarieven from "../views/Tarieven.vue";
// import Aks from "../components/Aks.vue";

const routes = [
  { 
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "Home",
    },
  },
  {
    path: "/overmij",
    name: "OverMij",
    component: OverMij,
    meta: {
      title: "OverMij",
    },
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
    meta: {
      title: "Contact",
    },
  },
  {
    path: "/galerij",
    name: "Galerij",
    component: Galerij,
    meta: {
      title: "Galerij",
    },
  },
  {
    path: "/tarieven",
    name: "Tarieven",
    component: Tarieven,
    meta: {
      title: "Tarieven",
    },
  },

  
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const DocT = `${process.env.VUE_APP_TITLE} | ${to.meta.title}`;
  document.title = DocT
  next()
});



export default router

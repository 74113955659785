<template>
	<main class="jumbotron">
		<article class="cont">
			<section class="col">
				<h2 class="tt2">
					Ik ben
					<br />
					Mohammad Abdolmaleki
				</h2>
			</section>
			<section class="mo">
				<h2 class="tt2">zeg maar</h2>
				<p class="tt2">Mo!</p>
			</section>
		</article>
	</main>
</template>

<script>
	export default {
		name: 'Mo2'
	}
</script>

<style scoped lang="scss">
	.jumbotron {
		background-image: url('../assets/img2/MoDesk8.png');
		top: 0;
		min-width: 100%;
		max-width: auto;
		min-height: 550px;
		max-height: auto;
		object-fit: cover;

		// Mobile S
		@media (min-width: 280px) and (max-width: 320px) and (orientation: portrait) {
			max-width: 300px;
			min-height: auto;
			max-height: 170px;
			margin: 0;
			top: -5px;
			padding: 0;
		}
		/* Mobile M */
		@media (min-width: 321px) and (max-width: 412px) and (orientation: portrait) {
			max-width: 350px;
			min-height: auto;
			max-height: 180px;
			margin-top: -125px;
		}

		/* Mobile L */
		@media (min-width: 412px) and (max-width: 480px) and (orientation: portrait) {
			max-width: 480px;
			min-height: auto;
			max-height: 200px;
			margin: 0;
			top: -125px;
			background-position: center;
			padding: 0;
		}
		/* Text container */
		.cont {
			width: 600px;
			display: flex;
			position: absolute;
			top: 100px;

			// Mobile S
			@media (min-width: 280px) and (max-width: 321px) and (orientation: portrait) {
				width: 260px;
				max-width: 320px;
				top: 25px;
				padding-left: 40px;
			}

			/* Mobile M */
			@media (min-width: 321px) and (max-width: 412px) and (orientation: portrait) {
				width: 300px;
				max-width: 360px;
				top: -60px;
				left: -40px;
			}

			/* Mobile L */
			@media (min-width: 412px) and (max-width: 480px) and (orientation: portrait) {
				width: 100%;
				top: 15px;
				padding-left: 50px;
			}
			/* Column */
			.col {
				width: 100%;
				margin-top: 50px;
				margin-left: 15px;

				// Mobile S
				@media (min-width: 280px) and (max-width: 320px) and (orientation: portrait) {
					width: 100%;
					margin-top: 5px;
					margin-left: 30px;
				}

				/* Mobile M */
				@media (min-width: 321px) and (max-width: 412px) and (orientation: portrait) {
					width: 100%;
					margin-left: 0;
				}

				/* Mobile L */
				@media (min-width: 412px) and (max-width: 480px) and (orientation: portrait) {
					width: 100%;
					margin-top: 0;
				}
				/* Ik ben  */
				h2 {
					font-size: 35px;

					// Mobile S
					@media (min-width: 280px) and (max-width: 320px) and (orientation: portrait) {
						font-size: 15px;
						left: -95px;
						top: -25px;
						position: relative;
						display: flex;
					}
					/* Mobile M */
					@media (min-width: 321px) and (max-width: 412px) and (orientation: portrait) {
						width: 200px;
						font-size: 17px;
						left: -5px;
						top: 20px;
						position: relative;
						display: flex;
					}

					/* Mobile L */
					@media (min-width: 412px) and (max-width: 480px) and (orientation: portrait) {
						width: 240px;
						font-size: 20px;
						left: -100px;
						top: -5px;
						position: relative;
						display: flex;
					}
				}
			}
			/* Mo Section */
			.mo {
				top: 100px;
				left: 120px;
				padding-top: 4px;
				position: absolute;
				display: flex;

				// Mobile S
				@media (min-width: 280px) and (max-width: 320px) and (orientation: portrait) {
					width: auto;
					height: auto;
					position: relative;
					display: flex;
					left: -135px;
					top: -45px;
				}
				/* Mobile M */
				@media (min-width: 321px) and (max-width: 412px) and (orientation: portrait) {
					width: 200px;
					margin-left: -45px;
					margin-top: -26px;
				}

				/* Mobile L */
				@media (min-width: 412px) and (max-width: 480px) and (orientation: portrait) {
					width: 240px;
					margin-left: -80px;
					margin-top: -85px;
				}
				/* Zeg maar */
				h2 {
					font-size: 50px;
					padding-top: 110px;
					margin-left: 60px;

					// Mobile S
					@media (min-width: 280px) and (max-width: 320px) and (orientation: portrait) {
						width: auto;
						font-size: 18px;
						margin-left: -85px;
						margin-top: 7px;
						margin-bottom: 0;
					}
					/* Mobile M */
					@media (min-width: 321px) and (max-width: 412px) and (orientation: portrait) {
						font-size: 19px;
						width: auto;
						margin: 0;
					}

					/* Mobile L */
					@media (min-width: 412px) and (max-width: 480px) and (orientation: portrait) {
						font-size: 20px;
						margin: 0;
					}
				}
				/* Mo */
				p {
					font-size: 50px;
					padding-top: 190px;
					padding-left: 0;
					margin-left: -150px;

					// Mobile S
					@media (min-width: 280px) and (max-width: 320px) and (orientation: portrait) {
						font-size: 30px;
						display: flex;
						margin-left: -65px;
						margin-top: -57px;
					}
					/* Mobile M */
					@media (min-width: 321px) and (max-width: 412px) and (orientation: portrait) {
						font-size: 32px;
						display: flex;
						margin-left: -75px;
						margin-top: -61px;
					}

					/* Mobile L */
					@media (min-width: 412px) and (max-width: 480px) and (orientation: portrait) {
						font-size: 35px;
						display: flex;
						margin-left: -75px;
						margin-top: -61px;
					}
				}
			}
		}
	}
</style>

<template>
	<article class="home">
		<Mo />

		<!-- <br />
		<hr />
		<br /> -->

		<!-- 		<Section
			titr="Personal Training"
			txt="Kan ik jou helpen om jouw doelen te bereiken? Meer bewegen,
					fitter worden, afvallen, conditie opbouwen, kracht trainen?
					Samen met jou maak ik een persoonlijk plan en werken we toe
					naar jouw doelen. Het maakt niet uit wat je huidige niveau
					is en hoe intensief je wilt trainen. Van beginner tot
					professional, iedereen is welkom! Buiten sporten of in de
					sportschool, alles is mogelijk! Zin om te starten? Neem
					contact op en we gaan aan de slag!"
      link="/overmij"
      btnText="Meer lezen"
      aks="FotoMo8.png"
      
		/> -->

		<!-- <br />
		<hr />
		<br /> -->

		<Section1 />

		<br />
		<hr />
		<br />
		<Section3 />

		<br />
		<hr />
		<br />
		<Section2 />

		<br />
		<hr />
		<br />
		<Section4 />
	</article>
</template>

<script>
	// @ is an alias to /src
	import Mo from '@/components/Mo.vue'
	// import Section from '@/components/Section.vue'
	import Section1 from "@/components/Section1.vue";
	import Section2 from "@/components/Section2.vue";
	import Section3 from "@/components/Section3.vue";
	import Section4 from "@/components/Section4.vue";

	export default {
		name: 'Home',
		components: {
			Mo,
			// Section,
			Section1,
			Section2,
			Section3,
			Section4,
		}
	}
</script>

<style lang="scss" scoped></style>

<template>
	<article class="">
		<Mo2 />

		<br />

		<section class="">
			<OverMijn id="ov" />
		</section>

		<br />
	</article>
</template>

<script>
	import OverMijn from '@/components/OverMijn.vue'
	import Mo2 from '@/components/Mo2.vue'
	export default {
		name: 'OverMij',
		components: {
			OverMijn,
			Mo2
		}
	}
</script>

<style scoped>
	/* Mobile S */
	@media (min-width: 280px) and (max-width: 320px) and (orientation: portrait) {
		#ov {
			margin-top: 5px;
		}
	}
</style>

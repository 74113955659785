<template>
	<article
		class="col-12-xs
					col-12-sm
					col-12-md
					col-12-ml
					col-12-lg
					col-12-xl
					col-12-xx "
	>
		<h1>Een paar oefenvideo's</h1>
		<section class="row justify-center gap-1">
			<div
				class="col-12-xs
					col-12-sm
					col-6-md
					col-6-ml
					col-4-lg
					col-4-xl
					col-4-xx "
				v-for="(fil, ind) in FelomData.felma"
				:key="ind"
			>
				<video
					:src="getFilUrl(fil.film)"
					:title="fil.name"
					:alt="fil.name"
					class="vid"
					controls
				/>
			</div>
		</section>
	</article>
</template>

<script>
	import FelomData from '../data/FelomData'

	export default {
		name: 'Felom',

		data() {
			return {
				FelomData,
			}
		},

		methods: {
			getFilUrl(film) {
				console.log('Ane boshod felma')
				return require('../assets/video/' + film)
			},
		},
	}
</script>

<style scoped lang="scss">
	h1 {
		font-size: 60px;
		margin: 30px 0;
		font-weight: normal;
	}
	.vid {
		width: 100%;
		height: auto;
		position: relative;
		border: 1px solid #0e103d;
		padding: 0;
		margin: 0 auto;
	}
</style>

<template>
	<article class="" id="ga">
		<h1>Een paar foto's</h1>

		<!-- <div class="" v-for="aks in AksData.aksha" :key="aks.id">
				<div class="" v-if="aks.id === 10 || aks.id === 12">
					<h4 class="lead">{{ aks.name }}</h4>
					<div class="col-12-xs col-12-sm">
				
						<img
							:src="getImgUrl(aks.img)"
							:key="aks.id"
							:title="aks.name"
							:alt="aks.alt"
							class="img-ver aks"
						/>
					</div>
				</div>
				<div v-else>
					<h4 class="lead">{{ aks.name }}</h4>
					<div class="col-12-xs col-12-sm col-4-lg">
						<img
							:key="aks.id"
							:src="getImgUrl(aks.img)"
							:title="aks.name"
							:alt="aks.alt"
							class="img aks"
						/>
					</div>
				</div>

				
			</div> -->

		<section class="row justify-center gap-1">
			<div
				class="col-12-xs
					col-12-sm
					col-12-md
					col-6-ml
					col-4-lg
					col-3-xl
					col-3-xx "
				v-for="aks in AksData.aksha"
				:key="aks.id"
			>
				<img
					:src="getImgUrl(aks.img)"
					:title="aks.name"
					:alt="aks.name"
					class="img aks "
				/>
			</div>
		</section>
	</article>
</template>

<script>
	import AksData from '../data/AksData'

	export default {
		name: 'Aks',

		data() {
			return {
				AksData,
				modal: false,
			}
		},

		methods: {
			getImgUrl(aks) {
				console.log('Ane boshod')
				return require('../assets/img2/' + aks)
			},

			modall() {
				this.modal = true
			},

			toggle() {
				this.modal = !this.modal
			},
		},
	}
</script>

<style scoped lang="scss">

article {
	@media (max-width: 768px) {
		margin-top: -40px;
	}
}


	
</style>
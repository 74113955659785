const FelomData = {
	felma: [
		{
			id: 1,
			name: 'Oefen video',
			film: 'Video (19).mp4'
		},
		{
			id: 2,
			name: 'Oefen video',
			film: 'Video (2).mp4'
		},
		{
			id: 3,
			name: 'Oefen video',
			film: 'Video (7).mp4'
		},
		{
			id: 4,
			name: 'Oefen video',
			film: 'Video (10).mp4'
		},
		{
			id: 5,
			name: 'Oefen video',
			film: 'Video (5).mp4'
		},
		{
			id: 6,
			name: 'Oefen video',
			film: 'Video (6).mp4'
		},
		{
			id: 7,
			name: 'Oefen video',
			film: 'VideoConvert3.mp4' 
		},
		{
			id: 8,
			name: 'Oefen video',
			film: 'Video (14).mp4'
		},
		{
			id: 9,
			name: 'Oefen video',
			film: 'Video (17).mp4'
		},
		{
			id: 10,
			name: 'Oefen video',
			film: 'Video (16).mp4'
		},
		{
			id: 11,
			name: 'Oefen video',
			film: 'VideoConvert1.mp4'
		},
		{
			id: 12,
			name: 'Oefen video',
			film: 'VideoConvert2.mp4'
		},
		{
			id: 13,
			name: 'Oefen video',
			film: 'Video (27).mp4'
		},
		{
			id: 14,
			name: 'Oefen video',
			film: 'Video (23).mp4'
		},
		{
			id: 15,
			name: 'Oefen video',
			film: 'Video_jadid.mp4'
		},
	]
}

export default FelomData

<template>
  <article class="">
    <footer>
      <p>
        Alle rechten voorbehouden door <br>
        <router-link to="/" class="a"
          >MoMaleki</router-link
        >
        &copy;{{ new Date().getFullYear() }}
      </p>
    </footer>
  </article>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped>
.footer {
  position: relative;
  bottom: 0;
}

.footer p a {
  text-decoration: none;
  color: #0e103d;
  font-size: 18px;
}
.footer p a:hover {
  opacity: .8;
}

  /* Landscape Tablet */
    @media only screen 
      and (min-device-width: 1112px) 
      and (max-device-width: 1112px) 
      and (orientation: Landscape) 
      and (-webkit-min-device-pixel-ratio: 2){
        .footer{
          position: relative;
          bottom: 0;
        }
      }




/* Mobile Vertical */
  @media only screen and (min-width: 320px) and (min-height: 567px) {
  
  }

  /* Mobile Landscape */
  @media only screen and (min-width: 567px) and (min-height: 320px) {
       
  }

  /* Tablet Vertical */
  @media only screen and (min-width: 768px) and (min-height: 1024px) {
  
  }

  /* Tablet Landscape  */
  @media only screen and (min-width: 1024px) and (min-height: 768px) {
  
  }

  /* Desktops */
  @media only screen and (min-width: 1440px) and (min-height: 900px) {
   
  }
</style>
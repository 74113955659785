import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

// Eigen Style
import '@/assets/styles/scss/main.scss'

createApp(App)
	.use(store)
	.use(router)
	.mount('#app')
